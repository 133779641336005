import Vue from 'vue';
import { get as getObject } from '@/core/objects';
import { formatMoney } from '@shopify/theme-currency';
import { removeProtocol } from '@shopify/theme-images';

import Cookies from 'js-cookie';

export const DEFAULT_CURRENCY = 'USD';

const CURRENCIES_LIST = {
    XCD: '$',
    TOP: '$',
    AUD: '$',
    XAF: 'FCFA',
    MGA: 'Ar',
    KWD: 'د.ك',
    IQD: 'ع.د',
    EUR: '€',
    NPR: 'Rs',
    XOF: 'CFA',
    NGN: '₦',
    WST: 'WS$ ',
    USD: '$',
    JMD: '$',
    MNT: '',
    AZN: 'M.',
    BGN: 'лв',
    LYD: 'ل.د',
    MAD: 'DH',
    BYN: 'Br',
    THB: '฿',
    LBP: 'L£',
    BRL: 'R$',
    PGK: 'K ',
    ZWL: '$',
    AOA: 'Kz',
    RWF: '',
    LAK: '₭',
    NIO: 'C$',
    CRC: '₡',
    TMT: 'm',
    INR: 'RS.',
    DZD: 'DA',
    MRU: 'UM',
    SRD: '$',
    AWG: 'AFL',
    KGS: 'ЛВ',
    NOK: 'kr',
    UYU: '$',
    KZT: '',
    BOB: 'BS',
    XPF: '',
    TJS: 'TJS',
    HNL: 'L',
    MVR: '.ރ',
    RSD: '',
    ANG: 'NAƒ',
    KYD: '$',
    TZS: '',
    GHS: '₵',
    GBP: '£',
    IMP: '£',
    IRR: '﷼',
    NAD: '$',
    ZAR: 'R ',
    BSD: '$',
    HUF: '',
    MDL: '',
    CZK: 'KČ',
    EGP: 'LE',
    ILS: '',
    JOD: 'د.ا',
    GNF: 'FG',
    TTD: '$',
    TWD: '$',
    KES: 'KSH',
    NZD: '$',
    SSP: '£',
    JEP: '£',
    TVD: '$',
    KHR: 'KHR',
    CUC: '$',
    CUP: '$',
    PYG: 'G',
    MXN: '$',
    TRY: '₺',
    BZD: '$',
    BBD: '$',
    FKP: '£',
    SGD: '$',
    LKR: 'Rs ',
    QAR: 'QAR',
    CNY: '¥',
    PEN: 'S/. ',
    GGP: '£',
    BHD: '.د.ب',
    ZMW: 'ZK',
    CLP: '$',
    RON: 'lei',
    MOP: 'MOP',
    DKK: '',
    FOK: 'kr',
    BIF: 'FBU.',
    SHP: '£',
    FJD: '$',
    GYD: '$',
    ETB: 'Br',
    SLL: 'Le ',
    PHP: '₱',
    MZN: 'MT',
    ERN: 'Nfk',
    VUV: '$',
    SBD: '$',
    ALL: 'LEK',
    CHF: 'CHF',
    RUB: '₽',
    CAD: '$',
    GEL: '₾',
    BDT: 'TK',
    DOP: '$',
    KRW: '₩',
    HKD: '$',
    UGX: 'USH',
    KID: '$',
    GMD: 'D',
    JPY: '¥',
    CKD: '$',
    BTN: 'Nu.',
    CVE: '$',
    BWP: 'P',
    GIP: '£',
    SZL: 'L',
    UAH: '₴',
    SEK: '',
    PAB: 'B/.',
    BMD: '$',
    ISK: '',
    VES: 'Bs.S.',
    AFN: '؋',
    BND: '$',
    CDF: 'FC',
    KPW: '₩',
    OMR: 'ر.ع.',
    AED: 'DHS. ',
    VND: '',
    GTQ: '',
    IDR: 'Rp',
    ARS: '$',
    COP: '$',
    MKD: 'den',
    DJF: '',
    AMD: '',
    LSL: 'L',
    TND: 'د.ت',
    STN: 'Db',
    SAR: '',
    PLN: 'zł',
    HTG: 'G',
    SYP: '£',
    PKR: '₨',
    SCR: '₨',
    MWK: 'MK',
    LRD: '$',
    MYR: 'RM',
    UZS: '',
    MMK: 'K',
    SOS: 'Sh',
    KMF: 'CF',
    YER: '',
    MUR: 'RS',
    BAM: 'KM',
    MRF: 'RF',
    STD: 'DB '
};

export const money = function (value) {
    return formatMoney(value, window?.theme?.moneyFormat);
};

export const moneyWithoutTrailingZeros = function (value) {
    return money(value).replace('.00', '');
};

export const convertCurrency = function (
    value, from, to
) {
    const Currency = window?.Currency;
    if (!Currency) {
        return null;
    }

    return Currency.convert(
        value, from, to
    );
};

export const convertCurrencyToSelected = function (value,
    from = DEFAULT_CURRENCY) {
    const Currency = window?.Currency;
    if (!Currency) {
        return null;
    }

    const selectedCurrency =
        Cookies.get('cart_currency') ||
        window?.Shopify?.currency?.active ||
        DEFAULT_CURRENCY;

    return Currency.convert(
        value, from, selectedCurrency
    );
};

export const moneyWithCurrencyProvided = function (value, currency, showCurrencyName = true, removeTrailingZeros = false) {
    const formatter = new Intl.NumberFormat(`${window?.Shopify?.locale?.toLowerCase()}-${window?.Shopify?.country?.toUpperCase()}`,
        {
            style: 'decimal',
            currency,
            currencyDisplay: 'symbol',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

    if (['KMF', 'ANG', 'CZK', 'INR', 'NIS'].includes(currency)) {
        return `${formatter.format(value / 100)} ${
            CURRENCIES_LIST[currency?.toUpperCase()] || ''
        }`;
    }

    if (['KHR', 'KSH', 'KGS', 'CHF', 'MOP', 'QAR', 'TJS'].includes(currency)) {
        return `${
            CURRENCIES_LIST[currency?.toUpperCase()] || ''
        }${formatter.format(value / 100)}`;
    }

    if (['BGN', 'CVE', 'LBP', 'PLN', 'RON', 'THB'].includes(currency)) {
        return `${formatter.format(value / 100)}${
            CURRENCIES_LIST[currency?.toUpperCase()] || ''
        } ${currency}`;
    }

    let output = `${CURRENCIES_LIST[currency?.toUpperCase()] || ''}${formatter.format(value / 100)}`;

    output += showCurrencyName ? ` ${currency}` : '';

    if (removeTrailingZeros) {
        output = output.replaceAll('.00', '').replaceAll(',00', '');
    }

    return output;
};

export const moneyConvertedToSelectedCurrency = function (value, from = DEFAULT_CURRENCY, showCurrencyName = true, removeTrailingZeros = false) {
    const selectedCurrency =
        Cookies.get('cart_currency') ||
        window?.Shopify?.currency?.active ||
        DEFAULT_CURRENCY;

    return moneyWithCurrencyProvided(convertCurrency(value, from, selectedCurrency), selectedCurrency, showCurrencyName, removeTrailingZeros);
};

export const moneyNoDecimals = function (value) {
    // eslint-disable-next-line no-template-curly-in-string
    return formatMoney(value, '${{amount_no_decimals}}');
};

export const unescapeValue = function unescape(value) {
    const doc = new DOMParser().parseFromString(value, 'text/html');

    return doc?.documentElement?.textContent || "";
};

export const moneyWithCurrency = function (value) {
    return formatMoney(value, unescapeValue(window?.theme?.moneyWithCurrencyFormat || ""));
};

export const getSizedImageUrl = function (src, size) {
    if (size === null) {
        return removeProtocol(src);
    }

    if (size === 'master') {
        return removeProtocol(src);
    }

    const [width = null, height = null] = size.split('x');

    const match = src?.match(/(?<path>.+)(?<suffix>\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif|webp|avif))(?<version>(\?v=\d+))?/i);
    if (!match) {
        return null;
    }

    const path = match?.groups?.path;
    if (!path) {
        return null;
    }

    const suffix = match?.groups?.suffix;
    if (!suffix) {
        return null;
    }

    const original_path = path?.replace(/(?<size>(_([\d]+)?x([\d]+)?))?(?<scale>(@([\d]+)?))?$/i, '');
    if (!original_path) {
        return null;
    }

    const paramsString = [
        !!width ? `width=${width}`: '',
        !!height ? `height=${height}` : '',
    ]?.filter((param) => {
        return !!param;
    })?.join('&') || "";

    return removeProtocol(`${original_path}${suffix}${paramsString ? `?${paramsString}` : ''}`);
};

export const img_url = function (src, size) {
    if (!src) {
        return getSizedImageUrl('https://cdn.shopify.com/s/files/1/1832/9335/files/placeholder.png', size);
    }

    if (!size) {
        return src;
    }

    return getSizedImageUrl(src, size);
};

export const asset_img_url = function (file, size = null) {
    const src = getObject('assetsUrl').replace('%%file%%', file);
    if (!src) {
        return src;
    }

    if (!size) {
        return src;
    }

    return getSizedImageUrl(src, size);
};

export const file_img_url = function (file, size = null) {
    const src = getObject('fileUrl').replace('%%file%%', file);

    if (!src) {
        return src;
    }

    if (!size) {
        return src;
    }

    return getSizedImageUrl(src, size);
};

export const t = function (value, params = {}) {
    return window.Theme.translate(value, params);
};

export const routes = function routes(value) {
    return getObject().routes(value);
};

export const default_value = function default_value(value, defaultValue) {
    if (!value) {
        return defaultValue;
    }

    return value;
};

Vue.filter('money', money);
Vue.filter('convertCurrency', convertCurrency);
Vue.filter('moneyConvertedToSelectedCurrency', moneyConvertedToSelectedCurrency);
Vue.filter('moneyWithCurrencyProvided', moneyWithCurrencyProvided);
Vue.filter('moneyNoDecimals', moneyNoDecimals);
Vue.filter('moneyWithCurrency', moneyWithCurrency);
Vue.filter('img_url', img_url);
Vue.filter('asset_img_url', asset_img_url);
Vue.filter('file_img_url', file_img_url);
Vue.filter('t', t);
Vue.filter('routes', routes);
Vue.filter('default_value', default_value);
Vue.filter('unescape', unescapeValue);

export default {
    money,
    moneyWithoutTrailingZeros,
    convertCurrency,
    moneyConvertedToSelectedCurrency,
    moneyWithCurrencyProvided,
    moneyNoDecimals,
    moneyWithCurrency,
    img_url,
    asset_img_url,
    file_img_url,
    t,
    routes,
    default_value,
    unescape: unescapeValue
};
